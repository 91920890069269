module.exports = [{
      plugin: require('/var/www/programe/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"backgroundColor":"transparent"},
    },{
      plugin: require('/var/www/programe/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/var/www/programe/src/layouts/index.js"},
    },{
      plugin: require('/var/www/programe/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
