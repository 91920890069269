import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Island1 from "../../images/islands/island1.png"
import Island2 from "../../images/islands/island2.png"
import Island3 from "../../images/islands/island3.png"
import Island4 from "../../images/islands/island4.png"
import Island5 from "../../images/islands/island5.png"
import Pergament from "../../images/pergament.jpg"

const mobileBreakpoint = `60rem`

const Container = styled.div`
  margin-top: 35vmin;

  @media (min-width: ${mobileBreakpoint}) {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 0;
    width: 100%;
    height: 100vh;
  }

  @media (min-width: 37.5rem) and (max-width: ${mobileBreakpoint}) {
    columns: 2;
    column-gap: 2rem;

    > a {
      height: 15rem;
    }

    > :last-child {
      column-span: all;
    }
  }
`

const linkStyle = css`
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10vmin;
  }
`

const Link = styled(GatsbyLink)(linkStyle)

const smaller = `(max-width: 1366px) and (min-width: ${mobileBreakpoint}), (max-height: 768px) and (min-width: ${mobileBreakpoint})`

const Label = styled.span`
  position: absolute;
  display: block;
  padding: 0 1.5rem;
  font-family: "Friz Quadrata W01", serif;
  font-size: 2.5rem;
  font-variant: small-caps;
  font-weight: 400;
  color: #4B4129;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  background: url(${Pergament});
  background-size: cover;

  @media (max-width: ${mobileBreakpoint}) {
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10vmin;
    white-space: nowrap;
  }

  @media (min-width: ${mobileBreakpoint}) {
    transition: box-shadow .15s ease-in-out, transform .15s ease-in-out;

    a:hover &,
    a:focus & {
      box-shadow: 0 0 75px rgba(0, 0, 0, 0.75);
      transform: translateY(-10px);
    }
  }

  @media ${smaller} {
    font-size: 2rem;
  }
`

const island = css`
  display: block;
  margin: 0 auto;
  max-width: 80vmin;
  width: 100%;
`

const Islands = () => (
  <Container>
    <a css={linkStyle} href="https://learn2programe.github.io/learn2proGrAME-Tutorial/" target="_blank" rel="noopener noreferrer">
      <img src={Island1} alt="" css={css`
        ${island}

        @media (min-width: ${mobileBreakpoint}) {
          margin-left: 0;
          position: absolute;
          width: 40vh;
          width: calc(10vmax + 25vmin);
          top: 10vh;
          left: 3vw;
          max-width: 1000px;
          max-height: none;
        }

        @media ${smaller} {
          width: calc(7vmax + 15vmin);
        }
      `} />
      <Label css={css`
        @media (min-width: ${mobileBreakpoint}) {
          top: 25vh;
          left: 16vw;
        }

        @media ${smaller} {
          left: 8vw;
        }
      `}>Tutorial</Label>
    </a>

    <Link to="/projekt">
      <img src={Island2} alt="" css={css`
        ${island}

        @media (min-width: ${mobileBreakpoint}) {
          margin-left: 0;
          position: absolute;
          width: 70vh;
          width: calc(25vmax + 28vmin);
          bottom: -8vh;
          right: -4vw;
          max-width: 1000px;
          max-height: none;
        }

        @media ${smaller} {
          width: calc(17vmax + 17vmin);
        }
      `} />
      <Label css={css`
        @media (min-width: ${mobileBreakpoint}) {
          right: 20vw;
          bottom: 25vh;
        }

        @media ${smaller} {
          right: 10vw;
        }
      `}>Projekt</Label>
    </Link>

    <Link to="/spiele">
      <img src={Island3} alt="" css={css`
        ${island}

        @media (min-width: ${mobileBreakpoint}) {
          margin-left: 0;
          position: absolute;
          width: 70vh;
          width: calc(20vmax + 40vmin);
          bottom: 3vh;
          left: 12vw;
          max-width: 1300px;
          max-height: none;
        }

        @media ${smaller} {
          width: calc(17vmax + 17vmin);
        }
      `} />
      <Label css={css`
        @media (min-width: ${mobileBreakpoint}) {
          bottom: 22vh;
          left: 36vw;
        }

        @media ${smaller} {
          bottom: 19vh;
          left: 24vw;
        }
      `}>Spiele</Label>
    </Link>

    <Link to="/aktuelles">
      <img src={Island4} alt="" css={css`
        ${island}

        @media (min-width: ${mobileBreakpoint}) {
          margin-left: 0;
          position: absolute;
          width: 45vh;
          width: calc(15vmax + 20vmin);
          top: 4vh;
          right: 1vw;
          max-width: 1400px;
          max-height: none;
        }

        @media ${smaller} {
          width: calc(10vmax + 12vmin);
        }
      `} />
      <Label css={css`
        @media (min-width: ${mobileBreakpoint}) {
          right: 10vw;
          top: 26vh;
        }

        @media ${smaller} {
          right: 5vw;
          top: 20vh;
        }
      `}>Aktuelles</Label>
    </Link>

    <Link to="/materialien">
      <img src={Island5} alt="" css={css`
        ${island}

        @media (min-width: ${mobileBreakpoint}) {
          margin-left: 0;
          position: absolute;
          width: 55vh;
          width: calc(15vmax + 28vmin);
          top: 2vh;
          left: 35vw;
          max-width: 1400px;
          max-height: none;
        }

        @media ${smaller} {
          width: calc(10vmax + 20vmin);
        }
      `} />
      <Label css={css`
        @media (min-width: ${mobileBreakpoint}) {
          top: 2vh;
          left: 42vw;
        }

        @media ${smaller} {
          left: 38vw;
        }
      `}>Materialien</Label>
    </Link>
  </Container>
)

export default Islands