import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition
} from "react-transition-group"

const timeout = 200
const getTransitionStyles = {
  entering: {
    transform: `scale(0.1)`,
    opacity: 0
  },
  entered: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 1,
    transform: `scale(1)`
  },
  exiting: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 0,
    transform: `scale(0.1)`
  }
}

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {status => (
            <div
              style={{
                ...getTransitionStyles[status],
              }}
              className="content-wrapper"
            >{children}</div>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition