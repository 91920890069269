// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-page-js": () => import("/var/www/programe/src/components/page.js" /* webpackChunkName: "component---src-components-page-js" */),
  "component---src-pages-aktuelles-js": () => import("/var/www/programe/src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-index-js": () => import("/var/www/programe/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spiele-js": () => import("/var/www/programe/src/pages/spiele.js" /* webpackChunkName: "component---src-pages-spiele-js" */)
}

