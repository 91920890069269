import React from "react"
import { Link } from "gatsby"
import Background from "./background"
import { UniLogo, ProgrameLogo, Nav } from "./overlays"

import Islands from "./islands"

const Scene = () => (
  <div>
    <Background />
    <Islands />
    <UniLogo />
    <Nav>
      <Link to="/impressum">Impressum</Link>
      <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>
    </Nav>
    <ProgrameLogo />
  </div>
)

export default Scene