/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { navigate } from "gatsby"

const onInitialClientRender = () => {
  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 27 && window.location.pathname !== '/')
      navigate('/')
  })
}

export { onInitialClientRender }