import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import uniLogoSrc from "../../images/uni-logo-white.svg"
import programeLogoSrc from "../../images/programe-logo.svg"

const mobileBreakpoint = `60rem`
const linkColor = `#cfcfcf`
const edge = css`
  position: absolute;
  z-index: 100;
`

const UniLogo = () => (
  <a href="https://www.univie.ac.at/" target="_blank" rel="noopener noreferrer" css={css`
    @media (max-width: ${mobileBreakpoint}) {
      position: relative;
      display: block;
      text-align: center;

      &:before {
        content: "Ein Forschungsprojekt der";
        display: block;
        margin-bottom: .5rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  `}>
    <img src={uniLogoSrc} alt="Universität Wien Logo" css={css`
      width: 50%;
      max-width: 16rem;

      @media (min-width: ${mobileBreakpoint}) {
        ${edge}
        top: 3rem;
        left: 3rem;
        width: 16rem;
      }
    `} />
  </a>
)

const ProgrameLogo = () => (
  <a href="https://learn2programe.github.io/learn2proGrAME-Tutorial/" target="_blank" rel="noopener noreferrer" css={css`
    display: block;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    z-index: 2;
    text-align: center;

    img {
      width: 80%;
      max-width: 30rem;
    }

    @media (min-width: ${mobileBreakpoint}) {
      top: 45%;
      left: 50%;
      width: 40%;
      max-width: none;
      margin-left: -20%;
      margin-top: -6vh;
      transform: none;

      img {
        width: 100%;
        max-width: none;
      }
    }
  `}>
    <img src={programeLogoSrc} alt="learn to proGrAME Logo" />
  </a>
)

const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem;
  color: ${linkColor};
  text-align: center;

  a {
    position: relative;
    color: ${linkColor};
    padding: .5rem 0;
  }

  a:hover,
  a:focus {
    color: #fff;
  }

  @media (min-width: ${mobileBreakpoint}) {
    ${edge}
    bottom: 2rem;
    left: 3rem;
    margin: 0;
    flex-direction: row;

    a {
      margin: 0;
      padding: 0;
    }

    a + a {
      margin-left: 1rem;
    }

    a + a::before {
      content: '·';
      color: ${linkColor};
      position: absolute;
      left: -1rem;
      width: 1rem;
      text-align: center;
    }
  }
`

export { UniLogo, ProgrameLogo, Nav }